<template>
    <div class="bg-page" style="min-height: 300px">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('我的银行卡')"
                     left-arrow
                     @click-left="onClickLeft"
                     @click-right="onClickRight"
        >
            <template #right>
                <span class="btn btn-black iconfont icon-plus"></span>
            </template>
        </van-nav-bar>
        <div class="page-main">
            <div class="bank-list" >
                <div class="bank-card" v-for="(item, index) in list"
                     :key="index"
                     @click="selectBank(item)"
                >
                    <template v-if="item.type == 0">
                        <div class="bank-head">
                            <div class="bank-tt">{{$t('Card no.')}}</div>
                        </div>
                        <div class="bank-body" style="font-size: 24px">
                            <div class="bank-number">
                                {{item.number.replace(/^(\w{4})\w+(\w{4})$/,"$1 **** **** $2")}}
                            </div>
                            <div class="bank-tag">
                                <div class="c-name">{{item.bank_name}}</div>
                            </div>
                        </div>
                    </template>
                    <template v-if="item.type == 1">
                        <div class="bank-head">
                            <div class="bank-tt">{{$t('Wallet Address')}}</div>
                        </div>
                        <div class="bank-body" >
                            <div class="bank-number">
                                {{item.number.replace(/^(\w{4})\w+(\w{4})$/,"$1 **** **** $2")}}
                            </div>
                            <div class="bank-tag">
                                <div class="c-name">{{item.bank_name}}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <van-popup class="page-popup" v-model="show2" position="bottom" closeable
                   :style="{ borderRadius: '10px'}">
            <div class="wt-dialog">
                <div class="wt-top">{{$t('选择取款方式')}}</div>
                <div class="wt-panel wt-list-cells">
                    <van-radio-group v-model="add_radio">
                        <van-cell v-if="usdt == '1'" center :title="$t('USDT（TRC20）')"
                                  :label="$t('强烈推荐TRC20，免手续费')" clickable
                                  @click="add_radio = 'TRC20'">
                            <template #icon>
                                <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="TRC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell v-if="usdt == '1'" center :title="$t('USDT（ERC20）')"
                                  clickable
                                  @click="add_radio = 'ERC20'">
                            <template #icon>
                                <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="ERC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell center :title="$t('银行卡')" clickable @click="add_radio = 'BANK'">
                            <template #icon>
                                <img src="../../assets/pictures/bank-ico.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="BANK">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-radio-group>
                    <div class="popbtn">
                        <van-row :gutter="10">
                            <van-col :span='12'><van-button  type="orange" class="nfobtn" plain size="normal" @click="show2 = false">{{$t('取消')}}</van-button></van-col>
                            <van-col :span='12'><van-button color="linear-gradient(to right, #3270e0, #3653ea)" size="normal" @click="toBind">{{$t('确定')}}</van-button></van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                show2: false,
                add_radio: 'TRC20',
                usdt: false,
                activeIcon: require('../../assets/pictures/w-checked.png'),
                inactiveIcon: require('../../assets/pictures/w-check.png'),
                list: [],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1);
            },
            onClickRight() {
                if (this.usdt) {
                    this.show2 = true
                } else {
                    this.$router.push({path: '/bank-bind'})
                }
            },
            toBind() {
                if (this.add_radio == 'BANK') {
                    this.$router.push({path: '/bank-bind',});
                } else if (this.add_radio == 'PIX') {
                    this.$router.push({path: '/pix-bind',});
                } else {
                    this.$router.push({path: '/bind-wallet', query: {channel: this.add_radio}});
                }
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?tag=list').then((res) => {
                    this.list = res.data.data.info;
                    this.usdt = res.data.data.usdt;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            selectBank(item) {
                if (item.type == 1) {
                    this.$router.push({path: '/bind-wallet', query: {id: item.id}});
                } else if (item.type == 2) {
                    this.$router.push({path: '/pix-bind', query: {id: item.id}});
                } else if (item.type == 0) {
                    this.$router.push({path: '/bank-bind', query: {id: item.id}});
                }
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
<style scoped>
    .icon-plus{
        max-width: 36px;
        border-radius: 10px;
    }
    .bank-card{
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        margin-bottom: 20px;
        font-size: 14px;
        padding: 16px;
    }
    .bank-card:nth-child(odd){
        background-image: url(../../assets/pictures/K2.png);
    }
    .bank-card:nth-child(even){
        background-image: url(../../assets/pictures/K1.png);
    }
    .bank-head{
        margin-bottom: 10px;
    }
    .bank-head .bank-tt{
        font-size: 15px;
    }
    .bank-number{
        font-size: 24px;
        margin-bottom: 20px;
    }
    .popbtn{
        padding: 15px;
    }
    .popbtn .van-button{
        width: 100%;
    }
    .wt-list-cells  ::v-deep .van-cell-group{
        background-color: transparent;
    }
    .wt-input_items ::v-deep .van-field__control{
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell-group::after{
        display: none;
    }
    .wt-list-cells  ::v-deep .van-cell{
        background-color: transparent;
        border: 1px solid #757a7b;
        border-radius: 10px;
        margin-bottom: 15px;
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell::after{
        display: none;
    }
    .wt-panel .wt-icon{
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
    .wt-panel .van-cell::after{
        border-color: #5d5d5d;
    }
    .van-radio__icon img{
        width: 20px;
        height: 20px;
    }
    .wt-panel{
        padding:15px
    }



</style>